<template>
    <div class="HdCateChart"></div>
</template>

<script>
    export default {
        name: '',
        data() {
            return {}
        },
        methods: {
            setChart() {

                //重大隐患
                let importantLevelNumber = 0;
                //一般隐患
                let commonLevelNumber = 0;
                //待确认
                let waitingConfirmNumber = 0;
                this.$http
                    .get('https://api.luojie-safe.cn/api/v2/hiddenTrouble/hiddenTroubleCnt')
                    .then(
                        response => {//successcallback
                            importantLevelNumber = response.body.data.importantLevelNumber;
                            commonLevelNumber = response.body.data.commonLevelNumber;
                            waitingConfirmNumber = response.body.data.waitingConfirmNumber;

                            let option = {
                                color: ['#fa5230',
                                    '#1774fb',
                                    '#2ecf4f'
                                ],
                                tooltip: {
                                    trigger: 'item'
                                },
                                legend: {
                                    y: 'center',
                                    left: 'right',
                                    orient: 'vertical',
                                    textStyle: {
                                        fontSize: 10,
                                        color: 'white',
                                    },
                                },
                                series: [{
                                    name: '隐患分类',
                                    type: 'pie',
                                    center: ['35%', '50%'],
                                    radius: ['30%', '60%'],
                                    avoidLabelOverlap: true,
                                    label: {
                                        normal: {
                                            show: true,
                                            fontWeight: 'bold',
                                            position: 'outside'
                                        },

                                    },
                                    emphasis: {
                                        label: {
                                            show: true,
                                            fontSize: '16',
                                            fontWeight: 'bold'
                                        }
                                    },
                                    labelLine: {
                                        normal: {
                                            show: true,
                                            length: 10,
                                            length2: 10,
                                            lineStyle: {
                                                color: '#3569c8'
                                            }
                                        }
                                    },
                                    data: [{
                                        value: importantLevelNumber,
                                        name: '重大隐患'
                                    },
                                        {
                                            value: commonLevelNumber,
                                            name: '一般隐患'
                                        },
                                        {
                                            value: waitingConfirmNumber,
                                            name: '待确认'
                                        }
                                    ]
                                }]
                            };

                            let myChart = this.$echarts(this.$el);
                            myChart.clear();
                            myChart.resize()
                            myChart.setOption(option);
                        },
                        response => {//errorcallback
                            alert("失败！");
                            console.log(JSON.stringify(response))//错误信息
                        })


            }
        },
        mounted() {
            this.setChart()
        },
    }
</script>

<style lang="less" scoped>
    .HdCateChart {
        height: 100%;
        width: 100%;
    }
</style>
